html {
  box-sizing: border-box;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body, #app {
  height: 100%;
  overflow: hidden;
}

#app {
  display: flex;
}

.punchcard-day-button {
  will-change: transform;
  transition: transform .3s cubic-bezier(.17, .67, .83, .67);
}

.punchcard-day-button:active {
  transform: scale3d(.9, .9, 1);
}

body {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/*# sourceMappingURL=index.96271668.css.map */
