html {
    box-sizing: border-box;
    overflow: hidden;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
#app {
    height: 100%;
    overflow: hidden;
}

#app {
    display: flex;
}

.punchcard-day-button {
    transition: transform 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    will-change: transform;
    &:active {
        transform: scale3d(0.9, 0.9, 1);
    }
}

body {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
